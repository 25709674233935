<!--  -->
<template>
  <div class="container">
    <div class="cus-cont">
      <van-cell icon="wechat-pay" title="微信收款信息(公众号)">
        <van-button plain type="primary" color="#FFB5BB" v-if="accountWxInfo && accountWxInfo.real_name"
          @click="handleModify(3)">修改</van-button>
      </van-cell>
      <template v-if="accountWxInfo">
        <div class="user-info">
          <img :src="accountWxInfo.headimg" />
          <div class="user-name">{{ accountWxInfo.name }}
            <div class="prompt">填写与微信钱包账户身份信息一致的姓名<van-icon name="question" @click="showWxPayLayer = true" /></div>
          </div>
        </div>
        <div class="val-cont" v-if="accountWxInfo.real_name">姓名：{{ accountWxInfo.real_name }}</div>
        <template v-else>
          <van-cell-group :border="false">
            <van-field v-model="wxRealName" placeholder="填写真实姓名" />
          </van-cell-group>
          <div class="submit-cont">
            <van-button plain type="primary" color="#FFB5BB" @click="setAccount(accountWxInfo, 3)">提交</van-button>
          </div>
        </template>
      </template>
      <div class="cus-popover" v-show="showWxPayLayer">
        <p>查看实名身份信息位置在：</p>
        <p>微信--我--服务--钱包--身份信息--实名认证状态</p>
      </div>
    </div>
    <div class="cus-cont">
      <van-cell icon="wechat-pay" title="微信收款信息(APP)">
        <!-- <van-button plain type="primary" color="#FFB5BB">添加</van-button> -->
      </van-cell>
      <div class="user-info" v-if="accountAppInfo">
        <img :src="accountAppInfo.headimg" />
        <div class="user-name">{{ accountAppInfo.name }}(昵称)</div>
      </div>
      <div class="download-list" v-else>
        <div class="code-item">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230117102810657.png" />
          <div class="down-text">
            <img src="../../assets/images/icon-apple.png" />
            IOS版下载
          </div>
        </div>
        <div class="code-item">
          <img src="http://freesaasofficedata.oss.aliyuncs.com/rolorweb/20230117102925219.png" />
          <div class="down-text">
            <img src="../../assets/images/icon-android.png" />
            Android版下载
          </div>
        </div>
      </div>
    </div>
    <div class="cus-cont">
      <van-cell icon="alipay" title="支付宝收款信息" :border="false">
        <van-button plain type="primary" color="#FFB5BB" v-if="accountZfbInfo" @click="handleModify(2)">修改</van-button>
        <template #label>
          为确保顺利收款，请谨慎输入与您授权姓名一致的账号
          <van-icon name="question" @click="showAliPayLayer = true" />
        </template>
      </van-cell>
      <template v-if="accountZfbInfo && accountZfbInfo.real_name">
        <div class="val-cont">姓名：{{ accountZfbInfo.real_name }}</div>
        <div class="val-cont">账号：{{ accountZfbInfo.account }}</div>
      </template>
      <template v-else>
        <van-cell-group :border="false">
          <van-field v-model="realName" placeholder="填写真实姓名" />
          <van-field v-model="account" placeholder="填写账号" />
        </van-cell-group>
        <div class="submit-cont">
          <van-button plain type="primary" color="#FFB5BB" @click="setAccount(accountZfbInfo, 2)">提交</van-button>
        </div>
      </template>
    </div>
    <div class="alipay-layer" v-show="showAliPayLayer">
      <div class="content">
        <span></span>
        <!-- <van-icon name="clear" /> -->
        <van-icon name="clear" @click="showAliPayLayer = false" />
        <img src="http://freesaasofficedata.oss.aliyuncs.com/miShop/20201106162310008.png" />
      </div>
    </div>
    <van-overlay :show="showWxPayLayer" @click="showWxPayLayer = false" style="background-color: rgba(0,0,0,0);" />
  </div>

</template>

<script>
export default {
  name: '',
  data() {
    return {
      wxRealName: '',
      realName: '',
      account: '',
      accountWxInfo: null,//公众号收款信息
      accountAppInfo: null,//APP收款信息
      accountZfbInfo: null,//支付宝收款信息
      showWxPayLayer: false,
      showAliPayLayer: false
    }
  },
  created() {
    this.get("/PersonCenter/Balance/GetPaymentAccountList", {}, 2).then(res => {
      if (res.code === 1) {
        res.response.forEach(item => {
          if (item.type === 1) this.accountAppInfo = item;
          else if (item.type === 2) this.accountZfbInfo = item;
          else if (item.type === 3) this.accountWxInfo = item;
        });
      }
    })
  },
  methods: {
    //点击修改
    handleModify(type) {
      if (type === 2) this.accountZfbInfo.real_name = "";
      else if (type === 3) this.accountWxInfo.real_name = "";
    },
    //设置账号
    setAccount(item, type) {
      let name = "";
      if (type === 2) {
        if (!this.realName) return this.$toast("请填写真实姓名")
        if (!this.account) return this.$toast("请填写账号");
        name = this.realName;
      } else if (type === 3) {
        if (!this.wxRealName) return this.$toast("请填写真实姓名");
        name = this.wxRealName;
      }
      this.$toast.loading({ message: '设置中...', duration: 0, overlay: true })
      this.get("/PersonCenter/Balance/SetPaymentAccount", {
        id: item ? item.id : 0, type, account: this.account, real_name: name
      }, 2).then((res) => {
        this.$toast(res.msg);
        if (res.code === 1) {
          if (type === 2) {
            this.accountZfbInfo.name = this.realName;
            this.accountZfbInfo.account = this.account;
          } else if (type === 3) this.accountWxInfo.real_name = this.wxRealName;
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.container {
  height: 100%;
  background-color: #F8F8F8;

  .cus-cont {
    margin-bottom: 11px;
    background-color: #fff;

    .van-cell {
      .van-cell__left-icon {
        font-size: 26px;
      }

      .van-cell__title {
        flex: 3;
      }

      .van-cell__value {
        .van-button {
          height: 25px;
          font-size: 12px;
          border-radius: 25px;
        }
      }

      .van-icon-wechat-pay {
        color: #4FCB78;
      }

      .van-icon-alipay {
        color: #53B3F0;
      }
    }

    .user-info {
      display: flex;
      padding: 11px 16px 11px 36px;
      align-items: center;
      font-size: 14px;

      img {
        width: 33px;
        height: 33px;
        margin-right: 10px;
        border-radius: 50%;
      }

      .prompt {
        color: #999;
        font-size: 10px;

        .van-icon {
          padding-left: 5px;
          font-size: 14px;
        }
      }
    }

    .val-cont {
      color: #666;
      font-size: 14px;
      padding: 11px 16px 11px 36px;
    }

    .cus-popover {
      position: absolute;
      right: 11px;
      background-color: #fff;
      z-index: 10;
      color: #FF98A0;
      font-size: 10px;
      padding: 5px 8px;
      border-radius: 3px;
      box-shadow: 0 6px 16px rgba($color: #000000, $alpha: 0.12);
    }

    .download-list {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 11px 0;

      .code-item {
        width: 100px;
        text-align: center;

        >img {
          width: 88px;
        }

        .down-text {
          display: flex;
          font-size: 10px;
          font-weight: bold;
          align-items: center;
          justify-content: center;

          img {
            width: 12px;
            margin-right: 5px;
          }
        }
      }
    }

    .van-cell-group {
      .van-field {
        padding-left: 46px;

        /deep/.van-field__value {
          font-size: 12px;
        }
      }
    }

    .submit-cont {
      padding: 15px 16px;
      position: relative;

      .van-button {
        width: 80%;
        height: 28px;
        font-size: 12px;
        margin-left: 10%;
        border-radius: 25px;
      }

      &:before {
        position: absolute;
        box-sizing: border-box;
        content: ' ';
        pointer-events: none;
        top: 0;
        right: 16px;
        left: 16px;
        border-bottom: 1px solid #ebedf0;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .alipay-layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);

    .content {
      width: 80%;
      position: relative;

      >img {
        width: 100%;
      }

      .van-icon-clear {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #b4b2b2;
        font-size: 20px;
      }
    }
  }
}
</style>